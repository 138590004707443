footer {
  text-align: center;
  font-size: 1.6rem;
  color: white;
  position: absolute;
  width: 32rem;
  bottom: -2.5rem;
  left: 50%;
  transform: translate(-50%, 50%);
  @media (max-width: 768px) {
    font-size: 1.4rem;
  }
}